import React from "react"
// import { Link } from "gatsby"
import styles from "./industries.module.sass"

import Technology from "./Technology/Technology"
import Industry from "./Industry/Industry"

// shapes
import BlockShape from "../../../icons/technologies/shapes/block.svg"
import BlockShapeHover from "../../../icons/technologies/shapes/block-hover.svg"
import CircleShape from "../../../icons/technologies/shapes/circle.svg"
import CircleShapeHover from "../../../icons/technologies/shapes/circle-hover.svg"
import ShieldShape from "../../../icons/technologies/shapes/shield.svg"
import ShieldShapeHover from "../../../icons/technologies/shapes/shield-hover.svg"
import SquareShape from "../../../icons/technologies/shapes/square.svg"
import SquareShapeHover from "../../../icons/technologies/shapes/square-hover.svg"

// icons
import NodeJsIcon from "../../../icons/technologies/icons/nodejs.svg"
import JavaScriptIcon from "../../../icons/technologies/icons/javascript.svg"
import PythonIcon from "../../../icons/technologies/icons/python.svg"
import GoLangIcon from "../../../icons/technologies/icons/golang.svg"
import TypeScriptIcon from "../../../icons/technologies/icons/typescript.svg"
import SolidityIcon from "../../../icons/technologies/icons/solidity.svg"
import LigoIcon from "../../../icons/technologies/icons/ligo.svg"
import ReactIcon from "../../../icons/technologies/icons/react_icon.svg"
import VueJSIcon from "../../../icons/technologies/icons/vuejs.svg"
import LessIcon from "../../../icons/technologies/icons/less.svg"
import SassIcon from "../../../icons/technologies/icons/sass.svg"
import SmartPyIcon from "../../../icons/technologies/icons/smartpy.svg"
import EthereumIcon from "../../../icons/technologies/icons/ethereu.svg"
import TronIcon from "../../../icons/technologies/icons/tron.svg"
import TezosIcon from "../../../icons/technologies/icons/tezos.svg"
import EosIcon from "../../../icons/technologies/icons/eos.svg"

const industries = [
  `Smart Contracts`,
  `DApp/DeFi Solutions`,
  `FinTech`,
  `Blockchain Solutions`,
  `Custom CRM/ERP`,
  `Cryptocurrency/Blockchain Infrastructure Solutions`,
  `Consultancy`,
  `Cryptocurrencies Payment Systems `,
  `Game theory/Economic analysis`,
]

const technologiesArray = [
  {
    key: 1,
    elements: [
      {
        icon: <NodeJsIcon />,
        shape: <ShieldShape />,
        shapeHover: <ShieldShapeHover />,
        title: `NodeJS`,
      },
      {
        icon: <JavaScriptIcon />,
        shape: <BlockShape />,
        shapeHover: <BlockShapeHover />,
        title: `JavaScript`,
      },
      {
        icon: <PythonIcon />,
        shape: <CircleShape />,
        shapeHover: <CircleShapeHover />,
        title: `Python`,
      },
      {
        icon: <GoLangIcon />,
        shape: <CircleShape />,
        shapeHover: <CircleShapeHover />,
        title: `Golang`,
      },
    ],
  },
  {
    key: 2,
    elements: [
      {
        icon: <TypeScriptIcon />,
        shape: <BlockShape />,
        shapeHover: <BlockShapeHover />,
        title: `TypeScript`,
      },
      {
        icon: <SolidityIcon />,
        shape: <CircleShape />,
        shapeHover: <CircleShapeHover />,
        title: `Solidity`,
      },
      {
        icon: <LigoIcon />,
        shape: <BlockShape />,
        shapeHover: <BlockShapeHover />,
        title: `Ligo`,
      },
      {
        icon: <ReactIcon />,
        shape: <ShieldShape />,
        shapeHover: <ShieldShapeHover />,
        title: `React`,
      },
    ],
  },
  {
    key: 3,
    elements: [
      {
        icon: <VueJSIcon />,
        shape: <SquareShape />,
        shapeHover: <SquareShapeHover />,
        title: `VueJS`,
      },
      {
        icon: <LessIcon />,
        shape: <ShieldShape />,
        shapeHover: <ShieldShapeHover />,
        title: `Less`,
      },
      {
        icon: <SassIcon />,
        shape: <SquareShape />,
        shapeHover: <SquareShapeHover />,
        title: `Sass`,
      },
      {
        icon: <SmartPyIcon />,
        shape: <BlockShape />,
        shapeHover: <BlockShapeHover />,
        title: `SmartPy`,
      },
    ],
  },
  {
    key: 4,
    elements: [
      {
        icon: <EthereumIcon />,
        shape: <CircleShape />,
        shapeHover: <CircleShapeHover />,
        title: `Ethereum`,
      },
      {
        icon: <TronIcon />,
        shape: <CircleShape />,
        shapeHover: <CircleShapeHover />,
        title: `Tron`,
      },
      {
        icon: <TezosIcon />,
        shape: <ShieldShape />,
        shapeHover: <ShieldShapeHover />,
        title: `Tezos`,
      },
      {
        icon: <EosIcon />,
        shape: <BlockShape />,
        shapeHover: <BlockShapeHover />,
        title: `EOS`,
      },
    ],
  },
]

const IndustriesScreen = () => {
  const renderTableHeader = () => {
    return technologiesArray.map(el => {
      return (
        <div className={styles.row} key={el.key}>
          {el.elements.map(innerEl => (
            <Technology tech={innerEl} key={innerEl.title} />
          ))}
        </div>
      )
    })
  }

  return (
    <section className={"section " + styles.industries}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={'heading ' + styles.heading}>
            <h2 className={styles.mobile}>Industries</h2>
            <h2>Industries & Technologies</h2>
            <p>
              An example of the cryptocurrency payment system gateway we
              developed
            </p>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.industries}>
              <p className={styles.heading}>MadFish experienced In:</p>
              <div className={styles.list}>
                {industries.map(el => (
                  <Industry industry={el} key={el} />
                ))}
              </div>
            </div>
            <div className={styles.technologies}>
              <div className={'heading ' + styles.heading + ' ' + styles.techn}>
                <h2 className={styles.mobile}>Technologies</h2>
                <p>
                  An example of the cryptocurrency payment system gateway we
                  developed
                </p>
              </div>
              {renderTableHeader()}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default IndustriesScreen
