import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { TimelineMax } from "gsap"
// import CSSRulePlugin from "gsap/CSSRulePlugin"
// gsap.registerPlugin(CSSRulePlugin)

// Components
import StyledCard from "../../Global/StyledCardV2/StyledCardV2"

// Styles
import styles from "./main.module.sass"

// Icons
import ArrowRight from "../../../icons/arrow-right-small.svg"
import ArrowLeft from "../../../icons/arrow-left-small.svg"

import ThanosWallet from "../../../icons/cases/ThanosWallet.svg"
import Swap from "../../../icons/cases/Swap.svg"
import Ligo from "../../../icons/cases/Ligo.svg"
import Zionodes from "../../../icons/cases/Zionodes.svg"
import ThanosWalletHover from "../../../icons/mainCases/thanos.svg"
import SwapHover from "../../../icons/mainCases/swap.svg"
import LigoHover from "../../../icons/mainCases/ligo.svg"
import ZionodesHover from "../../../icons/mainCases/zionodes.svg"

// import ThanosIcon from "../../../icons/mainCases/thanos.svg"
// import SwapIcon from "../../../icons/mainCases/swap.svg"
// import LigoIcon from "../../../icons/mainCases/ligo.svg"
// import ZionodesIcon from "../../../icons/mainCases/zionodes.svg"

const MainScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      thanosimage: file(
        relativePath: { eq: "first-screen/thanos-wallet.jpg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      swapimage: file(relativePath: { eq: "first-screen/quick-swap.jpg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      ligoimage: file(relativePath: { eq: "first-screen/ligo.jpg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      zionodesimage: file(relativePath: { eq: "first-screen/zionodes.jpg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const allContent = [
    {
      title: "Thanos Wallet",
      description:
        "Wallet for Tezos blockchain as a Web Extension for your Browser.",
      image: data.thanosimage.childImageSharp.fluid,
      link: "https://thanoswallet.com/",
    },
    {
      title: "Decentralize Exchange on Tezos blockchain",
      description:
        "QuipuSwap is a Uniswap inspired decentralized exchange on Tezos blockchain.",
      image: data.swapimage.childImageSharp.fluid,
      link: "https://quipuswap.com/",
    },
    {
      title: "Solidity to Ligo transpiler",
      description:
        "This project is Solidity to LIGO syntax converter and transpiler. It takes a .sol file as an input, parses it and yields PascalLIGO code as a result.",
      image: data.ligoimage.childImageSharp.fluid,
      link: "https://madfish-solutions.github.io/sol2ligo/",
    },
    {
      title: "Dedicated mining dashboard",
      description:
        "Imagine if you can build a Digital Ocean for mining equipment - yes, this service does it!",
      image: data.zionodesimage.childImageSharp.fluid,
      link: "https://www.zionodes.com/",
    },
  ]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [disabledArrow, setDisabledArrow] = useState(false)
  // const [currentSl, setCurrentSl] = useState(allContent[0].image)

  const handleArrowClick = amount => {
    disableArrows()
    let newSlide = currentSlide + amount
    if (newSlide > 3) newSlide = 0
    if (newSlide < 0) newSlide = 3
    mainCaseToggle(newSlide)
  }

  const handleCaseClick = amount => {
    disableArrows()
    if (amount !== currentSlide) mainCaseToggle(amount)
  }

  const disableArrows = () => {
    setDisabledArrow(!disabledArrow)
    setTimeout(() => {
      setDisabledArrow(false)
    }, 300)
  }

  const changeImage = slide => {
    setCurrentSlide(slide)
  }

  const mainCaseToggle = newSlide => {
    // const mainCasesThumbsItemActive = CSSRulePlugin.getRule(
    //   `.${styles.mainCase}::after`
    // )
    // const mainCasesThumbsItemActiveBefore = CSSRulePlugin.getRule(
    //   `.${styles.mainCase}::before`
    // )
    const tl = new TimelineMax({ paused: true })
    // tl.to(mainCasesThumbsItemActive, {
    //   duration: 0.5,
    //   opacity: 0,
    // })
    //   .to(mainCasesThumbsItemActiveBefore, {
    //     duration: 0.3,
    //     opacity: 1,
    //     delay: -0.3,
    //   })
    //   .to(`.${styles.mainCaseContent}`, {
    //     duration: 0.5,
    //     transform: "translate(1px, 1px)",
    //     delay: -0.7,
    //   })
    tl.to(`.${styles.mainCaseContent}`, {
      duration: 0.3,
      autoAlpha: 0,
      delay: -0.1,
      onComplete: changeImage,
      onCompleteParams: [newSlide],
    }).to(`.${styles.mainCaseContent}`, {
      duration: 0.3,
      autoAlpha: 1,
      delay: 0.2,
    })
    // .to(`.${styles.mainCaseContent}`, {
    //   duration: 0.5,
    //   delay: -0.1,
    //   transform: "none",
    // })
    // .to(mainCasesThumbsItemActiveBefore, {
    //   duration: 0.3,
    //   opacity: 0,
    //   delay: -0.5,
    // })
    // .to(mainCasesThumbsItemActive, {
    //   duration: 0.5,
    //   opacity: 1,
    //   delay: -0.3,
    // })
    tl.play()
  }

  return (
    <section className={"section " + styles.main}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={styles.wrapper}>
            <div className={styles.content}>
              <div className={styles.heading}>
                <h1>
                  Mad ideas<span>implemented wisely</span>
                </h1>
                <p>
                  Software development company experienced in Blockchain and JS
                  web with highly skilled professionals
                </p>
              </div>
              <div className={styles.navigation}>
                <div className={styles.buttons}>
                  <div className={styles.button}>
                    <StyledCard type="reverse">
                      <button
                        disabled={disabledArrow}
                        className="button button-small"
                        onClick={() => handleArrowClick(-1)}
                      >
                        <ArrowLeft />
                      </button>
                    </StyledCard>
                  </div>
                  <StyledCard type="reverse">
                    <button
                      disabled={disabledArrow}
                      className="button button-small"
                      onClick={() => handleArrowClick(1)}
                    >
                      <ArrowRight />
                    </button>
                  </StyledCard>
                </div>
                <h4>{allContent[currentSlide].title}</h4>
                <p>{allContent[currentSlide].description}</p>
              </div>
            </div>

            <div className={styles.cases}>
              <div className={styles.mainCase}>
                <div className={styles.mainCaseContent}>
                  {allContent.map((el, index) => (
                    <div key={el.title}>
                      <Img
                        fluid={el.image}
                        alt={el.title}
                        style={{
                          height: "100%",
                          opacity: index === currentSlide ? 1 : 0,
                        }}
                      />
                    </div>
                  ))}
                </div>
                <a
                  href={allContent[currentSlide].link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles.mainCaseLink}
                />
              </div>
              <div className={styles.thumbs}>
                <div className={styles.thumb}>
                  <StyledCard type={currentSlide === 0 ? " active" : ""}>
                    <button
                      className={styles.thumbContent}
                      disabled={disabledArrow}
                      onClick={() => handleCaseClick(0)}
                    >
                      <span
                        className={
                          styles.icon +
                          " " +
                          (currentSlide === 0 ? styles.active : "")
                        }
                      >
                        <ThanosWallet />
                        <span className={styles.iconHover}>
                          <ThanosWalletHover />
                        </span>
                      </span>
                    </button>
                  </StyledCard>
                </div>
                <div className={styles.thumb}>
                  <StyledCard
                    type={"withOpacity" + (currentSlide === 1 ? " active" : "")}
                  >
                    <button
                      className={styles.thumbContent}
                      disabled={disabledArrow}
                      onClick={() => handleCaseClick(1)}
                    >
                      <span
                        className={
                          styles.icon +
                          " " +
                          (currentSlide === 1 ? styles.active : "")
                        }
                      >
                        <Swap />
                        <span className={styles.iconHover}>
                          <SwapHover />
                        </span>
                      </span>
                    </button>
                  </StyledCard>
                </div>
                <div className={styles.thumb}>
                  <StyledCard
                    type={"withOpacity" + (currentSlide === 2 ? " active" : "")}
                  >
                    <button
                      className={styles.thumbContent}
                      disabled={disabledArrow}
                      onClick={() => handleCaseClick(2)}
                    >
                      <span
                        className={
                          styles.icon +
                          " " +
                          (currentSlide === 2 ? styles.active : "")
                        }
                      >
                        <Ligo />
                        <span className={styles.iconHover}>
                          <LigoHover />
                        </span>
                      </span>
                    </button>
                  </StyledCard>
                </div>
                <div className={styles.thumb}>
                  <StyledCard
                    type={"withOpacity" + (currentSlide === 3 ? " active" : "")}
                  >
                    <button
                      className={styles.thumbContent}
                      disabled={disabledArrow}
                      onClick={() => handleCaseClick(3)}
                    >
                      <span
                        className={
                          styles.icon +
                          " " +
                          (currentSlide === 3 ? styles.active : "")
                        }
                      >
                        <Zionodes />
                        <span className={styles.iconHover}>
                          <ZionodesHover />
                        </span>
                      </span>
                    </button>
                  </StyledCard>
                </div>
              </div>
              {/*<div className={styles.thumbsMobile}>*/}
              {/*    <button*/}
              {/*      className={styles.thumbMobile + ' ' + (currentSlide === 0 ? styles.active : "")}*/}
              {/*      disabled={disabledArrow}*/}
              {/*      onClick={() => handleCaseClick(0)}*/}
              {/*    />*/}
              {/*    <button*/}
              {/*      className={styles.thumbMobile + ' ' + (currentSlide === 1 ? styles.active : "")}*/}
              {/*      disabled={disabledArrow}*/}
              {/*      onClick={() => handleCaseClick(1)}*/}
              {/*    />*/}
              {/*    <button*/}
              {/*      className={styles.thumbMobile + ' ' + (currentSlide === 2 ? styles.active : "")}*/}
              {/*      disabled={disabledArrow}*/}
              {/*      onClick={() => handleCaseClick(2)}*/}
              {/*    />*/}
              {/*    <button*/}
              {/*      className={styles.thumbMobile + ' ' + (currentSlide === 3 ? styles.active : "")}*/}
              {/*      disabled={disabledArrow}*/}
              {/*      onClick={() => handleCaseClick(3)}*/}
              {/*    />*/}
              {/*</div>*/}
            </div>

            <div className={styles.descriptionMobile}>
              <h4>{allContent[currentSlide].title}</h4>
              <p>{allContent[currentSlide].description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }
//
// Header.defaultProps = {
//   siteTitle: ``,
// }

export default MainScreen
