import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./blog.module.sass"

// Icons
import ArrowRight from "../../../icons/arrow-right.svg"

const BlogScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      allMediumPost(sort: { fields: createdAt, order: DESC }, limit: 3) {
        edges {
          node {
            id
            uniqueSlug
            createdAt(formatString: "MMMM DD, YYYY")
            title
            virtuals {
              previewImage {
                imageId
              }
            }
            content {
              subtitle
            }
          }
        }
      }
    }
  `)

  // Equal height with resize detections
  function debounce(fn, ms) {
    let timer
    return () => {
      clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        fn.apply(this, arguments)
      }, ms)
    }
  }
  const [height, setHeight] = useState("auto")
  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      let maxHeight = 0
      const servicesCardsContent = document.querySelectorAll(
        `.${styles.inside}`
      )
      servicesCardsContent.forEach(el => {
        // console.log(el)
        if (el.offsetHeight > maxHeight) maxHeight = el.offsetHeight
      })
      if (window.innerWidth > 768) {
        if (maxHeight !== height) setHeight(maxHeight)
      } else {
        setHeight("auto")
      }
    }, 1000)
    debouncedHandleResize()

    window.addEventListener("resize", debouncedHandleResize)

    return () => {
      window.removeEventListener("resize", debouncedHandleResize)
    }
  }, [])

  return (
    <section className={"section"}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className="heading">
            <h2>Blog</h2>
            <p>
              Check out our news and updates about companies products and
              interesting technologies Fish
            </p>
          </div>
          <div className={styles.wrapper}>
            {data.allMediumPost.edges.map(post => (
              <div className={styles.post} key={post.node.id}>
                <StyledCard
                  type="hovered zindex"
                  external
                  link={`https://medium.com/madfish-solutions/${post.node.uniqueSlug}`}
                >
                  <div className={styles.content}>
                    <div className={styles.image}>
                      <img
                        srcSet={`https://miro.medium.com/max/552/${post.node.virtuals.previewImage.imageId} 276w, https://miro.medium.com/max/1104/${post.node.virtuals.previewImage.imageId} 552w, https://miro.medium.com/max/1280/${post.node.virtuals.previewImage.imageId} 640w, https://miro.medium.com/max/1400/${post.node.virtuals.previewImage.imageId} 700w`}
                        sizes="700px"
                        role="presentation"
                        src={`https://miro.medium.com/max/4096/${post.node.virtuals.previewImage.imageId}`}
                      />
                    </div>
                    <div style={{ height: height }}>
                      <div className={styles.inside}>
                        <p className={styles.date}>{post.node.createdAt}</p>
                        <h3>{post.node.title}</h3>
                        <p className={styles.description}>
                          {post.node.content.subtitle}{" "}
                          <button className="button button-arrow">
                            See more on Medium <ArrowRight />
                          </button>
                        </p>
                      </div>
                    </div>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default BlogScreen
