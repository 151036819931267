import React, { useState } from "react"
// import { Link } from "gatsby"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Styles
import styles from "./cases.module.sass"

// Icons
import ThanosWallet from "../../../icons/cases/ThanosWallet.svg"
import Swap from "../../../icons/cases/Swap.svg"
import Ligo from "../../../icons/cases/Ligo.svg"
import Zionodes from "../../../icons/cases/Zionodes.svg"
import ThanosWalletHover from "../../../icons/mainCases/thanos.svg"
import SwapHover from "../../../icons/mainCases/swap.svg"
import LigoHover from "../../../icons/mainCases/ligo.svg"
import ZionodesHover from "../../../icons/mainCases/zionodes.svg"
import ArrowRight from "../../../icons/arrow-right.svg"
import GithubIcon from "../../../icons/github.svg"

const cases = [
  {
    icon: <ThanosWallet />,
    iconHover: <ThanosWalletHover />,
    title: `Thanos Wallet`,
    content: `Wallet for Tezos blockchain as Web Extension for your Browser.`,
    link: `https://thanoswallet.com/`,
    linkOpen: `https://github.com/madfish-solutions/thanos-wallet`,
  },
  {
    icon: <Swap />,
    iconHover: <SwapHover />,
    title: `Decentralize Exchange on Tezos blockchain`,
    content: `QuipuSwap is a Uniswap inspired decentralized exchange on Tezos blockchain.`,
    link: `https://quipuswap.com/`,
    linkOpen: `https://github.com/madfish-solutions/quipuswap-core`,
  },
  {
    icon: <Ligo />,
    iconHover: <LigoHover />,
    title: `Solidity to Ligo transpiler`,
    content: `This project is Solidity to LIGO syntax converter and transpiler. It takes a .sol file as an input, parses it and yields PascalLIGO code as a result.`,
    link: `https://madfish-solutions.github.io/sol2ligo/`,
    linkOpen: `https://github.com/madfish-solutions/sol2ligo`,
  },
  {
    icon: <Zionodes />,
    iconHover: <ZionodesHover />,
    title: `Dedicated mining dashboard`,
    content: `Imagine if you can build a Digital Ocean for mining equipment - yes, this service does it!`,
    link: `https://www.zionodes.com/`,
    // linkOpen: `asdf`,
  },
]

const CasesScreen = () => {
  const [hovered, setHovered] = useState(null)
  const toggleMouseEnter = index => {
    setHovered(index)
  }
  const toggleMouseLeave = () => {
    setHovered(null)
  }

  const getCoords = elem => {
    const box = elem.getBoundingClientRect()

    return box.top + window.pageYOffset
  }
  const handleScroll = elem => {
    // console.log(getCoords(document.querySelector(elem)))
    // console.log(
    //   getCoords(document.querySelector(elem)) +
    //     parseFloat(
    //       window
    //         .getComputedStyle(document.querySelector(elem), null)
    //         .getPropertyValue("padding-top")
    //     ) -
    //     document.querySelector(`.header-module--header--1gU64`).offsetHeight
    // )
    window.scrollTo({
      top:
        getCoords(document.querySelector(elem)) +
        parseFloat(
          window
            .getComputedStyle(document.querySelector(elem), null)
            .getPropertyValue("padding-top")
        ) -
        document.querySelector(`.header`).offsetHeight,
      behavior: "smooth",
    })
  }

  return (
    <section className={"section"}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className="heading">
            <div className="heading-container">
              <h2>Cases</h2>
              <div className={styles.buttons}>
                {/*<Link*/}
                {/*  to="/"*/}
                {/*  className={"button button-primary " + styles.buttonPrimary}*/}
                {/*>*/}
                {/*  <span className="content">More cases</span>*/}
                {/*</Link>*/}
                {/*<a href="https://github.com/madfish-solutions" target="_blank" className="button button-secondary" rel="noopener noreferrer">*/}
                <a
                  href="https://github.com/madfish-solutions"
                  target="_blank"
                  className="button button-secondary"
                  rel="noopener noreferrer"
                >
                  <span className="content">More Cases</span>
                </a>
              </div>
            </div>
            <p>Portfolio that speak!</p>
          </div>
          <div className={styles.wrapper}>
            {cases.map(el => (
              <div className={styles.case} key={el.title}>
                <StyledCard
                  // isOpenSource={el.isOpenSource}
                  link={el.link}
                  linkOpen={el.linkOpen}
                  external
                  mouseEnter={() => toggleMouseEnter(el.title)}
                  mouseLeave={toggleMouseLeave}
                >
                  <div className={styles.content}>
                    <div
                      className={
                        styles.icon +
                        " " +
                        (hovered === el.title ? styles.active : "")
                      }
                    >
                      {el.icon}
                      <div className={styles.iconHover}>{el.iconHover}</div>
                    </div>
                    <div className={styles.info}>
                      <h3>{el.title}</h3>
                      <p>{el.content}</p>
                      <button className="button button-arrow">
                        view more <ArrowRight />
                      </button>
                    </div>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
          <div className={styles.bottom}>
            <span>Check out our</span>
            <a
              href="https://github.com/madfish-solutions"
              target="_blank"
              className="button button-secondary"
              rel="noopener noreferrer"
            >
              <span className={styles.withIcon + " content"}>
                <GithubIcon />
                Open-Source projects
              </span>
            </a>
            <span>or</span>
            <button
              onClick={() => handleScroll(`#contactsScreen`)}
              className="button button-primary"
              rel="noopener noreferrer"
            >
              <span className="content">Contact Us</span>
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CasesScreen
