import React, { useState } from "react"

// Components
// import Testimonial from "./Testimonial/Testimonial"
import StyledCard from "../../Global/StyledCardV2/StyledCardV2"

// Styles
import styles from "./testimonials.module.sass"

// Icons
import ArrowLeft from "../../../icons/arrow-left-small2.svg"
import ArrowRight from "../../../icons/arrow-right-small2.svg"
import Img from "gatsby-image"
import LinkedInIcon from "../../../icons/testimonials/linkedin.svg"
import ClutchIcon from "../../../icons/testimonials/clutch.svg"
import { graphql, useStaticQuery } from "gatsby"

// Content
const testimonialsArray = [
  {
    id: 0,
    name: "Maxim Orlovsky",
    title: `CEO — Pandora Core AG`,
    country: "Switzerland",
    linkedin: "https://www.linkedin.com/in/dr-orlovsky/",
    goodfirms: {
      link: "https://www.goodfirms.co/company/madfish-solutions",
      stars: 5,
    },
    content: `We collaborated for Madfish for several years working on the distributed computing project, where he both acted as a co-investor and was involved into day-to-day software development management. He is one of the best in his field and is always willing to share best practices. His industry knowledge helped us to find more efficient way of software implementation, minimize expenses and improve the planning. He is also very responsible and detail-oriented, qualities that make him stand out. I loved working in the same project and I recommend to use services of his company.`,
  },
  {
    id: 1,
    name: "Alex Momot",
    title: `Founder & CEO — REMME`,
    country: "Ukraine",
    linkedin: "https://www.linkedin.com/in/alex-momot-93596b32/",
    goodfirms: {
      link: "https://www.goodfirms.co/company/madfish-solutions",
      stars: 5,
    },
    content: `I had the pleasure of working with Madfish. Their ability to introduce the strategic vision and articulate complex business processes is impressive. I may confirm that their insider's view and level of expertise in blockchain technology and alternative currencies were crucial to the success of our numerous projects. They always comes up with spot-on solutions reflecting their profound understanding of Blockchain and BFSI.`,
  },
  {
    id: 2,
    name: "Max Frolov",
    title: `CEO & Co-Founder — DataRoot Labs`,
    country: "Ukraine",
    linkedin: "https://www.linkedin.com/in/maxsilent/",
    goodfirms: {
      link: "https://www.goodfirms.co/company/madfish-solutions",
      stars: 5,
    },
    content: `Madfish is a company with joined-up thinking and comprehensive knowledge in Crypto World with executive experience in several cryptocurrency projects as well. Taking up executive positions they always remained a down to earth, pragmatic and sophisticated problem-solver, alleviating common day-to-day worries that all managers are familiar with. I would highly recommend working with Madfish.`,
  },
  {
    id: 3,
    name: "Martijn Verbove",
    title: `Founder — Convergate`,
    country: "Netherlands",
    linkedin: "https://www.linkedin.com/in/martijnverbove/",
    goodfirms: {
      link: "https://www.goodfirms.co/company/madfish-solutions",
      stars: 5,
    },
    content: `I had the pleasure of working with Madfish over the course of a year, during our collaboration on a very challenging project they proved to be a highly motivated and talented partners. I'd highly recommend Madfish for both software and hardware related projects.`,
  },
  {
    id: 4,
    name: "Svitlana Surodina",
    title: `CEO — Skein`,
    country: "United Kingdom",
    linkedin: "https://www.linkedin.com/in/svsur/",
    clutch: {
      link: "https://www.goodfirms.co/company/madfish-solutions",
      stars: 5,
    },
    content: `We were very pleased with the results of the work, including the quality of the deliverables. We had a direct contact with the project manager as well as develops directly, and had regular calls and face-to-face meeting, as well as slack and email chats. The expert level of coding skills, passion about their work and customer focus. The team could benefit from extra long-term planning as their team and number of project they simultaneously work on grow.`,
  },
]

const TestimonialsScreen = () => {
  const data = useStaticQuery(graphql`
    query {
      Maxim_Orlovsky: file(
        relativePath: { eq: "testimonials/Maxim_Orlovsky.jpeg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Alex_Momot: file(relativePath: { eq: "testimonials/Alex_Momot.jpg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Max_Frolov: file(relativePath: { eq: "testimonials/Max_Frolov.jpg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Martijn_Verbove: file(
        relativePath: { eq: "testimonials/Martijn_Verbove.jpg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Svitlana_Surodina: file(
        relativePath: { eq: "testimonials/Svitlana_Surodina.jpeg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const imagesArr = [
    data.Maxim_Orlovsky,
    data.Alex_Momot,
    data.Max_Frolov,
    data.Martijn_Verbove,
    data.Svitlana_Surodina,
  ]

  const [currentPerson, setCurrentPerson] = useState(0)
  const stars = []
  if (testimonialsArray[currentPerson].clutch) {
    for (let i = 0; i < testimonialsArray[currentPerson].clutch.stars; i++)
      stars.push(
        <span key={i} className={styles.star + ` ` + styles.active}>
          ★
        </span>
      )
    if (testimonialsArray[currentPerson].clutch.stars < 5)
      for (
        let i = 0;
        i < 5 - testimonialsArray[currentPerson].clutch.stars;
        i++
      )
        stars.push(
          <span key={i} className={styles.star}>
            ★
          </span>
        )
  }

  const handleArrowClick = amount => {
    let newSlide = currentPerson + amount
    if (newSlide > testimonialsArray.length - 1) newSlide = 0
    if (newSlide < 0) newSlide = testimonialsArray.length - 1
    setCurrentPerson(newSlide)
    // console.log(newCur)
  }

  return (
    <section className={"section"}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className="heading">
            <h2>Testimonials</h2>
            <p>
              Let us be your secret weapon when it comes to innovative
              technologies
            </p>
          </div>
          <div className={styles.navigation}>
            {/*<div className={styles.numbers}>*/}
            {/*  <span className={styles.current}>{currentPerson + 1}</span> of{" "}*/}
            {/*  <span>{testimonialsArray.length}</span>*/}
            {/*</div>*/}
            <div className={styles.buttons}>
              <div className={styles.button}>
                <StyledCard type="reverse border-small">
                  <button
                    className="button button-small"
                    onClick={() => handleArrowClick(-1)}
                  >
                    <ArrowLeft />
                  </button>
                </StyledCard>
              </div>
              <StyledCard type="reverse border-small">
                <button
                  className="button button-small"
                  onClick={() => handleArrowClick(1)}
                >
                  <ArrowRight />
                </button>
              </StyledCard>
            </div>
          </div>
          <div className={styles.wrapper}>
            {/*Testimonial*/}
            <div className={styles.contentWrapper}>
              <div className={styles.photoOuter}></div>
              <div className={styles.imageWrapper}>
                <div className={styles.imageInner}>
                  <div className={styles.image}>
                    <Img
                      fluid={imagesArr[currentPerson].childImageSharp.fluid}
                      alt={imagesArr[currentPerson].base.split(".")[0]} // only use section of the file extension with the filename
                    />
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.photoInner}></div>
                <div className={styles.left}>
                  <div className={styles.info}>
                    <a
                      href={testimonialsArray[currentPerson].linkedin}
                      target="_blank"
                      rel="noreferrer noopener"
                      className={styles.person}
                    >
                      <h4>
                        {testimonialsArray[currentPerson].name}{" "}
                        <span>rated&nbsp;5/5</span>
                      </h4>
                      <span className={styles.linkedin}>
                        {testimonialsArray[currentPerson].title}
                        <LinkedInIcon />
                      </span>
                      <span className={styles.country}>
                        {testimonialsArray[currentPerson].country}
                      </span>
                    </a>
                    {testimonialsArray[currentPerson].clutch && (
                      <a
                        href={testimonialsArray[currentPerson].clutch.link}
                        target="_blank"
                        rel="noreferrer noopener"
                        className={styles.goodfirms}
                      >
                        <span className={styles.stars}>{stars}</span>
                        <span>
                          <ClutchIcon />
                        </span>
                      </a>
                    )}
                  </div>
                  <div className={styles.comment}>
                    "{testimonialsArray[currentPerson].content}“
                  </div>
                </div>
                {/*<div className={styles.right}>*/}
                {/*  <div className={styles.caseWrapper}>*/}
                {/*    <Link to="index-2" className={styles.link}></Link>*/}
                {/*    <div className={styles.icon}>*/}
                {/*      <CaseIcon />*/}
                {/*    </div>*/}
                {/*    <div className={styles.case}>*/}
                {/*      <h3>QuipuSwap</h3>*/}
                {/*      <p>Oleh’s case - a modern website with great</p>*/}
                {/*      <button className="button button-arrow">*/}
                {/*        See the case <ArrowRight />*/}
                {/*      </button>*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestimonialsScreen
